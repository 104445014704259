import { cn } from "#client/common/utils";
import type { HTMLAttributes, PropsWithChildren } from "react";

export function LandingSectionHorizontalLayout({
  children,
  className,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  return (
    <div
      className={cn("mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8", className)}
      {...props}
    >
      {children}
    </div>
  );
}

export function LandingSectionVerticalLayout({
  children,
  className,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  return (
    <div className={cn("w-full py-24 sm:py-32", className)} {...props}>
      {children}
    </div>
  );
}

export function LandingPageSection({
  children,
  className,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  return (
    <LandingSectionVerticalLayout>
      <LandingSectionHorizontalLayout className={className} {...props}>
        {children}
      </LandingSectionHorizontalLayout>
    </LandingSectionVerticalLayout>
  );
}
